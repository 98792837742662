"use client";

import React, { useEffect, useState } from "react";
import { Configurators, ConfiguratorStepType } from "@/common/entities/configurators/Configurator";
import style from "./configuratorStepSelect.module.scss";
import { Button, Spinner } from "react-bootstrap";
import LocalStorageService from "@/services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "@/services/localStorageService/LocalStorageKeys";
import { sendCartData } from "@/services/CartService";
import toast from "react-hot-toast";
import { Products } from "@/common/entities/product/Product";
import FloatingProductsConfigurator from "@/components/ConfiguratorsProducts/FloatingProductsConfigurator";
import { getLatestValueForEuro } from "@/services/ConfiguratorService";
import { generateRandomString } from "@/common/helpers/util.functions";

interface CategoryProductsListProps {
    completedSteps: number;
    configurator: Configurators;
    selectedOptions: any;
    resetSteps: () => void;
}

const ConfiguratorPrice = ({ completedSteps, configurator, selectedOptions, resetSteps }: CategoryProductsListProps) => {
    const [requiredStepsCount, setRequiredStepsCount] = useState<number>(0);
    const [finalCost, setFinalCost] = useState<number>(0);
    const [oldFinalCost, setOldFinalCost] = useState<number>(0);
    const [finalProducts, setFinalProducts] = useState<Products[]>([]);
    const [euroValue, setEuroValue] = useState<number>(0);
    const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);

    useEffect(() => {
        getLatestValueForEuro().then((result) => setEuroValue(result.value));
    }, []);

    const calculateFinalCost = () => {
        let optionsTotal: number = 0;
        let oldPriceTotal: number = 0;
        let isPromo: boolean = false;

        selectedOptions.map((option: any) => {
            const step = configurator.configuratorSteps[option.stepId];
            if ([ConfiguratorStepType.select, ConfiguratorStepType.selectDropdown].includes(step.type)) {
                optionsTotal += Number(option.value);

                const selectedProduct: any = step.configuratorStepsProducts && step.configuratorStepsProducts[option.cardId];
                if (Number(selectedProduct?.specialPriceWithVat) > 0) {
                    isPromo = true;
                }

                if (isPromo) {
                    oldPriceTotal += Number(selectedProduct?.priceWithVat);
                }
            }
        });

        setOldFinalCost(oldPriceTotal);
        setFinalCost(optionsTotal);
    };

    useEffect(() => {
        calculateFinalCost();
    }, []);

    useEffect(() => {
        countRequiredSteps();
        if (completedSteps >= requiredStepsCount) {
            updateFinalProducts();
            calculateFinalCost();
        }
    }, [configurator, completedSteps, requiredStepsCount, selectedOptions]);

    const countRequiredSteps = () => {
        const count = configurator.configuratorSteps.filter((step) => step.isRequired).length;
        setRequiredStepsCount(count);
    };

    const updateFinalProducts = () => {
        const selectedProducts = selectedOptions.map((option: any) => {
            const step = configurator.configuratorSteps[option.stepId];

            if ([ConfiguratorStepType.select, ConfiguratorStepType.selectDropdown].includes(step.type)) {
                return step?.configuratorStepsProducts?.length ? step?.configuratorStepsProducts[option.cardId] : null;
            }

            return null;
        });

        const filteredSelectedProducts = selectedProducts
            .map((product: any) => {
                const productObj: Products = product?.product ? product?.product : product;

                if (productObj?.productCode) {
                    return productObj;
                }
            })
            .filter(Boolean);

        setFinalProducts(filteredSelectedProducts);
    };

    const handleAddToCart = async () => {
        setIsAddingToCart(true);
        let cartId = LocalStorageService.getItem(LocalStorageKeys.cartId);
        const configuratorName = getConfiguratorName();
        const configuratorCode: string = generateRandomString(32);

        try {
            for (const product of finalProducts) {
                if (!cartId) {
                    const cartResponse = await sendCartData({
                        productId: product.id,
                        quantity: 1,
                        originConfiguratorId: configurator.id,
                        originConfiguratorCode: configuratorCode,
                        originConfiguratorName: configuratorName,
                    });
                    cartId = cartResponse.id;
                    LocalStorageService.setItem(LocalStorageKeys.cartId, cartId, 3600 * 24 * 30);
                    LocalStorageService.setItem(LocalStorageKeys.cart, cartResponse, 3600 * 24 * 30);
                } else {
                    const newCart = await sendCartData({
                        cartId: cartId,
                        productId: product.id,
                        quantity: 1,
                        originConfiguratorId: configurator.id,
                        originConfiguratorCode: configuratorCode,
                        originConfiguratorName: configuratorName,
                    });
                    LocalStorageService.setItem(LocalStorageKeys.cartId, newCart.id, 3600 * 24 * 30);
                    LocalStorageService.setItem(LocalStorageKeys.cart, newCart, 3600 * 24 * 30);
                }
            }
            toast.success(`A fost adaugat in cos.`);
            setIsAddingToCart(false);
            resetSteps();
        } catch (error) {
            LocalStorageService.clear();
            console.error("Error adding product to cart:", error);
            toast.error("A aparut o eroare, te rugam adauga produsul din nou in cos.");
            setIsAddingToCart(false);
        }
    };

    const getConfiguratorName = () => {
        let configuratorName = configurator.name;
        const stepsCt = 2;

        for (let step = 0; step < stepsCt; step++) {
            let valueName = "";

            if ((configurator.configuratorSteps[step]?.configuratorStepsCharacteristics ?? []).length > 0) {
                valueName = selectedOptions.find((opt: any) => opt.stepId === step)?.value;
            } else if ((configurator.configuratorSteps[step]?.configuratorStepsProducts ?? []).length > 0) {
                const cardId = selectedOptions.find((opt: any) => opt.stepId === step)?.cardId;
                valueName = (configurator?.configuratorSteps[step]?.configuratorStepsProducts![cardId] as any)?.name;
            }

            configuratorName += " - " + valueName;
        }

        return configuratorName;
    };

    return (
        <div className={style.configuratorPrice}>
            {completedSteps < requiredStepsCount ? (
                <>
                    <div>
                        <div>
                            Preturi incepand cu <div className={style.configuratorCost}>{Number(configurator.totalCost).toFixed(2)} lei</div>
                        </div>
                        <div className={style.selectStepsText}>Selecteaza primii {requiredStepsCount} pasi pentru pret exact.</div>
                    </div>
                    <div className="mt-md-2">
                        <Button variant="primary" className="p-2 p-md-3 px-md-5 mt-md-0 mb-md-0" onClick={handleAddToCart} disabled={true}>
                            Adauga in Cos
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <div className="order-sm-1">
                        {oldFinalCost > 0 && <div className={style.oldPriceText}>PRET vechi: {oldFinalCost} lei</div>}
                        <div className={style.finalPrice}>{finalCost.toFixed(2)} lei</div>
                        <div className={style.finalPriceEuro}>({(finalCost / euroValue).toFixed(2)} €) pretul include TVA</div>
                    </div>
                    <div className="my-auto me-1 my-md-2 order-3 order-md-2">
                        <Button
                            variant="primary"
                            className="p-2 p-md-3 px-md-5 mt-md-0 mb-md-0"
                            onClick={!isAddingToCart ? handleAddToCart : undefined}
                            disabled={isAddingToCart ? true : false}
                        >
                            {isAddingToCart ? <Spinner animation="border" size="sm" /> : ""} Adauga in Cos
                        </Button>
                    </div>

                    <div className="my-auto mt-sm-3 order-2 order-md-3">
                        <FloatingProductsConfigurator
                            products={finalProducts}
                            observations={configurator.includedProductsObservation}
                            configuratorTitle={getConfiguratorName()}
                            totalPrice={finalCost}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default ConfiguratorPrice;
