"use client";

import React, { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import SwipperCarousel from "../SwipperCarousel/SwipperCarousel";
import { initLightboxJS, SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import style from "./productThumbnailGallery.module.scss";
import styled from "styled-components";
import Image from "next/image";
import { ImageWrapper } from "@/common/components/Images/ImageWrapper";
import WarrantyIcon from "@/images/warranty-icon.png";
import { AwsFile } from "@/common/entities/files/AwsFiles";
import { awsS3BaseURL } from "@/lib/Constants";
import useDebounce from "@/common/hooks/useDebounce";
import { ProductLabels } from "@/common/entities/product/Product";

const GallerySwipper = styled(SwipperCarousel)`
    margin-top: 0.7rem;

    .swiper-button-prev,
    .swiper-button-next {
        @media (max-width: 767.98px) {
            background: none;
        }

        background: #ffffffba;
        height: 96%;
        padding: 1.2rem;
        color: #000 !important;
        border-radius: 0 !important;

        &.swiper-button-disabled {
            @media (min-width: 768px) {
                display: none;
            }
        }

        &:after {
            font-size: 1.4rem;
            color: #000;
        }
    }

    .swiper-button-next {
        top: 26px;
        right: 3px;
    }

    .swiper-button-prev {
        top: 26px;
        left: 3px;
    }

    .swiper-slide {
        padding: 0.25rem;
    }

    .swiper-pagination-bullets {
        @media (max-width: 768px) {
            width: 100%;
            height: 50px;
            padding: 0;
        }
    }
`;

const PSwiperSlide = styled(SwiperSlide)`
    padding: 0.45rem;
    background: #fff;
    border-radius: 0.2rem;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
`;

const PrimaryImage = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    padding: 0.6rem;
`;

const ImageContainer = styled.div`
    @media (min-width: 768px) {
        height: 130px;
    }

    width: 100%;
    overflow: hidden;
    position: relative;
    object-fit: cover;
`;

const WrapperImage = styled.div`
    @media (max-width: 767.98px) {
        display: none;
    }

    position: relative;
    background: #fff;
    border-radius: 0.2rem;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

type ProductImage = {
    id: number;
    cover: boolean;
    image: AwsFile;
};

type ProductThumbnailGalleryProps = {
    images: ProductImage[];
    defaultImage: ProductImage;
    productLabels?: ProductLabels;
    isMobile?: boolean;
};

const ProductThumbnailGallery: React.FC<ProductThumbnailGalleryProps> = ({ images, defaultImage, productLabels, isMobile }) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(defaultImage);
    const [startingIndex, setStartingIndex] = useState(0);

    const debouncedFetchSearchResults = useDebounce(setCurrentImage, 300);

    const onSlideHoverImage = (image: ProductImage) => {
        debouncedFetchSearchResults(image);
    };

    const openLightbox = (imgId: number) => {
        setLightboxOpen(true);
        setStartingIndex(images.findIndex((obj) => obj.id === imgId));
    };

    useEffect(() => {
        initLightboxJS("6B2F-9B71-105E-2498", "team");
    }, []);

    const swiperSlideCard = images.map((image: ProductImage, index: number) => (
        <PSwiperSlide key={index} onClick={() => openLightbox(image.id)} onMouseEnter={() => onSlideHoverImage(image)}>
            <ImageContainer>
                <ImageWrapper width={136} height={136} url={image.image?.highSizeFileName} title={image.image?.altName} className="img-fluid" />
            </ImageContainer>
        </PSwiperSlide>
    ));

    const formattedImages = images.map(({ image }) => ({
        src: awsS3BaseURL + image?.originalFileName,
        alt: image?.altName,
    }));

    const breakpoints = {
        100: {
            slidesPerView: 1,
            spaceBetween: 0,
            slidesToScroll: 1,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 5,
        },
    };

    return (
        <>
            <WrapperImage>
                <PrimaryImage
                    width={800}
                    height={800}
                    className="img-fluid"
                    src={awsS3BaseURL + currentImage?.image?.originalFileName}
                    alt={currentImage?.image?.altName || ""}
                    onClick={() => openLightbox(currentImage.id)}
                />
                {productLabels && (
                    <>
                        <div className={style.labelIcons}>
                            {productLabels?.warranty && (
                                <div className={style.icon}>
                                    <div className={style.warranty}>
                                        <Image width={60} height={60} src={WarrantyIcon.src} alt="warranty" />
                                        <div className={style.value}>
                                            {productLabels.warranty}
                                            <div className={style.afterValue}>ANI</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {productLabels?.pro && (
                                <div className={style.icon}>
                                    <Image width={60} height={60} src={productLabels.pro} alt="pro" />
                                </div>
                            )}
                            {productLabels?.ecoDesign && (
                                <div className={style.icon}>
                                    <Image width={60} height={60} src={productLabels.ecoDesign} alt="eco design" />
                                </div>
                            )}
                        </div>
                        {productLabels?.energyClass && (
                            <div className={style.energyClassIcon}>
                                <Image width={60} height={30} src={productLabels.energyClass} alt="energy class" />
                            </div>
                        )}
                    </>
                )}
            </WrapperImage>

            {images && images.length > 1 && (
                <GallerySwipper
                    loop={isMobile ? true : false}
                    navigation={true}
                    spaceBetween={5}
                    slidesPerView={4}
                    elements={swiperSlideCard}
                    breakpoints={breakpoints}
                    pagination={isMobile ? true : false}
                />
            )}

            <SlideshowLightbox
                images={formattedImages}
                showThumbnails={true}
                open={lightboxOpen}
                startingSlideIndex={startingIndex}
                lightboxIdentifier="lbox1"
                modalClose={"clickOutside"}
                onClose={() => {
                    setLightboxOpen(false);
                }}
                backgroundColor="white"
            ></SlideshowLightbox>
        </>
    );
};

export default ProductThumbnailGallery;
